import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div>
        {posts &&
          posts.map(({ node: post }) => (
            <article key={post.id} className="media">
              <figure className="media-left" style={{ marginLeft: 0 }}>
                <p className="image">
                  <img
                    src={
                      post.frontmatter.featuredimage.childImageSharp.fluid.src
                    }
                  />
                </p>
              </figure>
              <div className="media-content">
                <h2 className="title is-4">
                  <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                </h2>
                <h3 className="subtitle is-5 mb-2">
                  By: {post.frontmatter.author}
                </h3>
                <p>{post.frontmatter.description}</p>
              </div>
            </article>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { in: ["blog-post", "restaurant-page"] }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200, format: HTML)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                author
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
