import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout title="Things to do">
        <section className="section">
          <div className="container">
            <h3 className="subtitle is-2">Welcome to Austin!</h3>
            <p className="mb-6">
              Here are some of our wedding party’s recommendations for things to
              do while you are visiting.
            </p>
            <BlogRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
